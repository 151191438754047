/* global $ */
$(document).ready(function () {
  function dataAnalytics (val) {
    window.dataLayer.push({
      'event': {
        'click': val
      }
    })
  }

  $('a[data-analytics]').on('click', function () {
    dataAnalytics($(this).data('analytics'))
  })
})
